export default {
  POST_CREATE_SPEAKER: 'speakers',
  GET_SPEAKERS: 'speakers?workspace=:workspace_id',
  PUT_UPDATE_SPEAKER_BY_ID: 'speakers/:id',
  DELETE_SPEAKER_BY_ID: 'speakers/:id',
  GET_VIDEO_SETTINGS: 'video_settings?workspace=:workspace_id',
  GET_CHAT_SETTINGS: 'workspaces/webrtc_chat_settings/:workspace_id',
  POST_SET_USER_PERMISSIONS: 'user_workspaces/validate',
  POST_ADD_PRESENTATION: 'converter',
  POST_ADD_IMAGE: 'images',
  GET_ALL_IMAGES: 'images',
  DELETE_IMAGE_BY_ID: 'images/:id',
  PACK_DELETE_IMAGE: 'images/pack_delete',
  POST_MAKE_SNAPSHOT: 'workspaces/snapshot',
  GET_SNAPSHOT: 'workspaces/snapshot/:workspace',
  POST_CREATE_SCENE: 'scenes',
  GET_SCENES: 'scenes?limit=100',
  GET_SCENE_BY_ID: 'scenes/:id',
  PUT_UPDATE_SCENE_BY_ID: 'scenes/:id',
  DELETE_SCENE_BY_ID: 'scenes/:id',
  POST_ADD_IMAGE_ON_SCENE: 'scenes/add_image/:scene_id/:image_id',
  POST_REMOVE_IMAGE_ON_SCENE: 'scenes/remove_image/:scene_id/:image_id',
  POST_STOP_RENDER: 'stop_render',
  GET_INTERACTIVES: 'interactive_settings?limit=100&room_id=:room_id',
  GET_CLOUD_WORDS:
    'cloud_words?limit=500&order_by=id&desc=1&interactive_setting_id=:interactive_id',
  GET_CLOUD_WORD: 'cloud_words/:room_id/weight_list',
  UPDATE_CLOUD_WORD: 'cloud_words/:id',
  POST_CREATE_BRANDING: 'branding',
  GET_BRANDING: 'branding',
  PUT_UPDATE_BRANDING_BY_ID: 'branding/:id',
  DELETE_BRANDING_BY_ID: 'branding/:id',
  POST_MAKE_FLASHPHONER_REQUEST: 'video_setting/flashphoner_call',
  POST_CREATE_DESTINATION: 'retranslation_destinations',
  GET_DESTINATIONS: 'retranslation_destinations?workspace=:workspace',
  PUT_UPDATE_DESTINATION_BY_ID: 'retranslation_destinations/:id',
  DELETE_DESTINATION_BY_ID: 'retranslation_destinations/:id',
  GET_BANNER_TIMER: 'banner_timer',
  DELETE_BANNER_TIMER_BY_ID: 'banner_timer/:id',
  UPDATE_BANNER_TIMER_BY_ID: 'banner_timer/:id',
  POST_MAKE_SET_HOST_REQUEST: 'video_setting/set_host',
  POST_MAKE_STOP_RENDER_REQUEST: 'video_setting/stop_render',
  START_EGRESS: 'start_egress',
}
