export default {
  DEFAULT_WIDTH: 1920,
  DEFAULT_HEIGHT: 1080,
  IDEAL_WIDTH: 1280,
  IDEAL_HEIGHT: 720,
  IDEAL_WIDTH_MOBILE: 360,
  IDEAL_HEIGHT_MOBILE: 640,
  MIN_BITRATE: 2000,
  MAX_BITRATE: 450000,
  ASPECT_RATIO: 16 / 9,
  ASPECT_RATIO_MOBILE: 9 / 16,
}
