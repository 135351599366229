import Vue from 'vue'
import App from './app.vue'
import router from './router'
import store from './store'
import VIcon from './components/v-icon'
import {
  detectMobileDevice,
  detectMobileDeviceBaseOnScreenWidth,
} from './helpers/detectMobileDevice'
import { setCookie } from '@/helpers/cookie'

Vue.component(VIcon.name, VIcon)

Vue.prototype.$isMobile =
  detectMobileDevice() || detectMobileDeviceBaseOnScreenWidth()

Vue.config.productionTip = false

setCookie('token-s', 'rci+NMf9DAg1OjHB8IdaJ1e+4xKXgWVYSoCjQEIVw8o=')
setCookie('name-s', 'render-server')

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
