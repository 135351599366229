export default {
  namespaced: true,
  state: () => ({
    orators: [],
  }),
  getters: {
    getOrators: (state) => state.orators,
  },
  actions: {
    addOrator({ commit }, orator) {
      commit('ADD_ORATOR', orator)
    },
    updateOrator({ commit }, { id, data }) {
      commit('UPDATE_ORATOR', { id, data })
    },
  },
  mutations: {
    ADD_ORATOR(state, orator) {
      state.orators.push(orator)
    },
    UPDATE_ORATOR(state, { id, data }) {
      const oratorIndex = state.orators.findIndex((el) => el.id === id)

      for (let key in data) {
        state.orators[oratorIndex][key] = data[key]
      }
    },
    REMOVE_ORATOR(state, oratorId) {
      state.orators.splice(
        state.orators.findIndex((el) => +el.id === +oratorId),
        1
      )
    },
  },
}
