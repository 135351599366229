export default function recursiveDecodeURIComponent(obj) {
  if (typeof obj === 'string') {
    if (!obj) {
      return obj
    }
    return decodeURIComponent(obj.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25'))
  }

  if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      obj[i] = recursiveDecodeURIComponent(obj[i])
    }
  } else if (typeof obj === 'object' && obj !== null) {
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        obj[key] = recursiveDecodeURIComponent(obj[key])
      }
    }
  }

  return obj
}
