import { ParticipantEvent } from 'livekit-client'
import Vue from 'vue'
export default {
  namespaced: true,
  state: () => ({
    remoteParticipants: [],
    localParticipant: null,
  }),
  getters: {
    getParticipants: (state) => {
      return state.remoteParticipants
    },
    getLocalParticipant: (state) => {
      return state.localParticipant
    },
    getParticipantByIdentity: (state, getters, rootState) => (identity) => {
      return rootState.room.room.getParticipantByIdentity(identity)
    },
    getHostPartisipants: (state) => {
      return state.remoteParticipants.filter((participant) => {
        if (participant[1].metadata) {
          const metadata = JSON.parse(participant[1].metadata)
          return metadata.isHost === true
        }
      })
    },
  },
  actions: {
    async setParticipant({ commit, dispatch }, room) {
      const localParticipant = room.localParticipant
      commit('SET_LOCAL_PARTICIPANT', [localParticipant.sid, localParticipant])
      commit('SET_REMOTE_PARTICIPANTS', room.remoteParticipants)
      room.remoteParticipants.forEach((participant) => {
        dispatch('participantEvents', participant)
      })
      dispatch('participantEvents', localParticipant)
      console.log(localParticipant)
    },
    participantEvents({ commit }, participant) {
      participant.on(ParticipantEvent.TrackSubscriptionFailed, (sid) => {
        console.error(ParticipantEvent.TrackSubscriptionFailed, sid)
      })
      participant.on(ParticipantEvent.TrackUnmuted, () => {
        if (participant.isLocal) {
          commit('track/SET_LOCAL_TRACK', [participant.sid, participant], {
            root: true,
          })
        }
      })
    },
    async setLocalParticipantMetaData({ getters }, payload) {
      let currentData = {}
      const participant = getters.getLocalParticipant[1]
      if (participant.metadata) {
        currentData = JSON.parse(participant.metadata)
      }
      const metadata = {
        ...currentData.metadata,
        ...payload,
      }
      const data = JSON.stringify({ ...metadata })
      participant.setMetadata(data)
      async function resolveSetMetadata() {
        return new Promise((resolve) => {
          participant.on(ParticipantEvent.ParticipantMetadataChanged, () => {
            resolve()
          })
        })
      }
      return await resolveSetMetadata()
    },
  },
  mutations: {
    SET_REMOTE_PARTICIPANTS(state, participants) {
      console.log(participants)
      Vue.set(state, 'remoteParticipants', Array.from(participants))
      this.commit('track/SET_REMOTE_TRACKS', participants, {
        root: true,
      })
    },
    SET_LOCAL_PARTICIPANT(state, participant) {
      Vue.set(state, 'localParticipant', participant)
    },
  },
}
