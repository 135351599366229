<template lang="pug">
div.the-stream
  div.the-stream__container
    div.the-stream__column
      v-waiting-room( ref='orators' style='display: none;')
      v-scene(
        ref='activeScene'
        :banner='banner'
        :branding='branding'
        :scene='scene'
        :stream-orator-width='streamOratorWidth'
        :stream-orator-height='streamOratorHeight'
        :timer='timer')
  div.localVideo(ref="localVideo")
  div.localVideo(ref="localVideoRestream")
  div.localVideo(ref="remoteScreenSharing")
</template>

<script>
import Vue from 'vue'
import BannerTimer from '@/models/banner-timer'
import axios from 'axios'
import MESSAGE_EVENTS from '@/constants/message-events'
import api from '@/constants/api'
import { getCookie } from '@/helpers/cookie'
import { ParticipantEvent, RoomEvent } from 'livekit-client'

import recursiveDecodeURIComponent from '@/helpers/recursive-decode-uri-component'
import { mapActions, mapGetters } from 'vuex'
import { decode, encode } from '../models/encrypt'

import resourceMain from '../../config'

export default {
  name: 'TheStream',
  components: {
    VPresentationControls: () => import('@/components/v-presentation-controls'),
    VPreview: () => import('@/components/v-preview'),
    VSettingsChange: () => import('@/components/v-settings-change'),
    VPill: () => import('@/components/v-pill'),
    VWaitingRoom: () => import('@/components/v-waiting-room.vue'),
    VScene: () => import('@/components/v-scene.vue'),
  },
  data: () => ({
    hostInterval: null,
    countdownTimer: '',
    timerStopWorkHost: false,
    stopWorkHost: false,
    banner: null,
    checkHealthIntervalList: {},
    music: {},
    presentation: {
      totalPages: null,
      currentPage: 1,
      origins: [],
      id: null,
    },
    scene: {
      layout: [],
      screenSharing: false,
      sceneAudioMix: {},
      decorations: [],
      background: [],
      presentations: [],
      music: [],
      videos: [],
      orator: [],
      modeScreen: 'SIMPLE',
      isShowTitle: false,
    },
    startRelayIntervalList: {},
    streamOratorWidth: 1920,
    streamOratorHeight: 1080,
    timer: null,
    video: {},
    emptyRoomTimer: null,
  }),
  computed: {
    ...mapGetters('studio', ['getOrators']),
    ...mapGetters('branding', [
      'getPrimaryColor',
      'getSecondaryColor',
      'getInteractiveBg',
      'getInteractiveTitle',
    ]),
    ...mapGetters('stream', [
      'getDestinations',
      'getRemoteVideoSettings',
      'getSocketServerURL',
    ]),
    ...mapGetters('participant', [
      'getLocalParticipant',
      'getParticipants',
      'getHostPartisipants',
    ]),
    ...mapGetters('room', ['getLivekitRoom']),
    ...mapGetters('track', [
      'getLocalAudioTrack',
      'getLocalVideoTrack',
      'getParticipantStream',
    ]),
    branding() {
      return {
        primaryColor: this.getPrimaryColor,
        secondaryColor: this.getSecondaryColor,
      }
    },
    rtmpUrl() {
      if (this.getRemoteVideoSettings.provider === 'kinescope') {
        return `${this.getRemoteVideoSettings.rtmp_link}/${this.getRemoteVideoSettings.streamkey}`
      } else if (this.getRemoteVideoSettings.provider === 'cdnnow') {
        return `rtmp://v9.proofix.tv/static/${this.getRemoteVideoSettings.stream_name}`
      }
      return null
    },
  },
  watch: {
    ['scene.presentations'](presentations) {
      const msgObj = {
        payload: presentations,
        type: MESSAGE_EVENTS.PRESENTATION_APPEAR,
      }
      this.sendMessageToAll(msgObj)
    },
    scene(scene) {
      const guetsLoaderScene = true
      const msgObj = {
        payload: [
          scene,
          guetsLoaderScene,
          this.getInteractiveBg,
          this.getInteractiveTitle,
        ],
        type: MESSAGE_EVENTS.ACTIVE_SCENE_UPDATE,
      }
      this.sendMessageToAll(msgObj)
    },
    'getLivekitRoom.state': function (state) {
      console.log(state)
    },
  },
  async mounted() {
    this.setRoom()
    await Promise.all([
      this.loadRemoteVideoSettings(),
      this.getRemoteSavedState(),
    ])
    await axios
      .post(
        `${resourceMain.API.api_studio.root}${api.POST_SET_USER_PERMISSIONS}`,
        {
          workspace: this.$route.query?.workspace,
        },
        {
          headers: {
            Authorization: `Bearer ${getCookie('token-s')}`,
          },
        }
      )
      .then(async (result) => {
        await this.initWebrtc({
          workspace: this.$route.query?.workspace,
          roomId: result.data.data.room_id,
        })
      })
    await this.setLocalParticipantMetaData({ isServerRender: true })
    console.log(
      this.participantStream,
      'console.log(this.participantStream.getTracks())'
    )
    this.messageEvents()
    this.getLivekitRoom.on(RoomEvent.TrackPublished, (_, participant) => {
      const msgObj = {
        payload: this.scene.presentations,
        type: MESSAGE_EVENTS.PRESENTATION_APPEAR,
      }
      this.sendMessage(msgObj, participant)
    })
  },
  methods: {
    ...mapActions('stream', [
      'getDestinationsList',
      'updateDestinationLocalParams',
      'loadRemoteVideoSettings',
    ]),
    ...mapActions('branding', [
      'setPrimaryColor',
      'setSecondaryColor',
      'getRemoteSavedState',
    ]),
    ...mapActions('room', ['initWebrtc', 'setRoom']),
    ...mapActions('participant', ['setLocalParticipantMetaData']),
    ...mapActions('track', ['replaceScreenTrack', 'stopUpstreamTracks']),
    messageEvents() {
      this.getLivekitRoom.on(
        ParticipantEvent.DataReceived,
        (encodedMesage, participant, kind, topic) => {
          let msg = ''
          if (decode(encodedMesage)) {
            msg = JSON.parse(decode(encodedMesage))
          }
          console.log(topic)
          switch (topic) {
            case MESSAGE_EVENTS.CHANGE_ACTIVE_BANNER:
              if (msg.banner?.isActive) {
                console.log(msg.banner)
                this.banner = new BannerTimer(
                  recursiveDecodeURIComponent(msg.banner)
                )
              } else {
                this.banner = null
              }
              break
            case MESSAGE_EVENTS.CHANGE_ACTIVE_TIMER:
              if (msg.timer?.isActive) {
                this.timer = new BannerTimer(
                  recursiveDecodeURIComponent(msg.timer)
                )
              } else {
                this.timer = null
              }
              break
            case MESSAGE_EVENTS.GUEST_CONNECT:
              console.log(msg)
              // this.hostConnect()
              this.guestConnectUpdateScene()
              // this.guestPresentatiomConnectUpdateScene()
              break
            case MESSAGE_EVENTS.UPDATE_SCENE:
              console.log(msg, 'msg')
              this.$store.commit(
                'branding/INTERACTIVE_BACKGROUND',
                msg.newScene?.interactive[0]?.json_settings
                  .results_background_color ||
                  msg.newScene?.interactive[0]?.json_settings
                    .results_background_img
              )
              // this.hostConnect()
              if (msg.newScene?.id) {
                console.log(msg.newScene)
                this.scene = recursiveDecodeURIComponent(msg.newScene)
              }
              break
            case MESSAGE_EVENTS.STOP_PUBLISHING:
              // this.stopUpstreamTracks(true)
              // this.stopRender()
              break
            case MESSAGE_EVENTS.VIDEO_IS_PLAYING:
              msg.isPlaying ? this.playVideo(msg) : this.pauseVideo(msg)
              break
            case MESSAGE_EVENTS.MUSIC_IS_PLAYING:
              msg.isPlaying ? this.playMusic(msg) : this.pauseMusic(msg)
              break
            case MESSAGE_EVENTS.SET_MUSIC_LOOP:
              this.onToggleLooping(msg.isLooping)
              break
            case MESSAGE_EVENTS.CHANGE_BRANDING:
              this.setPrimaryColor(msg.branding.primaryColor)
              this.setSecondaryColor(msg.branding.secondaryColor)
              break
            case MESSAGE_EVENTS.CHANGE_PRES_PAGE:
              this.scene.presentations[0] = msg
              Vue.set(this.scene.presentations, [0], msg)
              break
            case MESSAGE_EVENTS.GET_SERVER_RENDER_ACTIVE_SCENE_ID:
              console.log(this.scene)
              this.sendMessageToAll({
                payload: { activeSceneId: this.scene.id },
                type: MESSAGE_EVENTS.CHECK_ACTIVE_SCENE_ID,
              })
              break
            // case MESSAGE_EVENTS.CHECK_PRESENTATION_PAGE:
            //   this.sendMessageToAll({
            //     payload: this.scene.presentations,
            //     type: MESSAGE_EVENTS.PRESENTATION_APPEAR,
            //   })
            //   break
          }
        }
      )
      this.getLivekitRoom.on(RoomEvent.TrackPublished, (_, participant) => {
        const guetsLoaderScene = true
        const msgObj1 = {
          payload: [
            this.scene,
            guetsLoaderScene,
            this.getInteractiveBg,
            this.getInteractiveTitle,
          ],
          type: MESSAGE_EVENTS.ACTIVE_SCENE_UPDATE,
        }
        this.sendMessage(msgObj1, participant)

        console.log(this.scene.id, this.scene)
        const msgObj2 = {
          payload: { activeSceneId: this.scene.id },
          type: MESSAGE_EVENTS.CHECK_ACTIVE_SCENE_ID,
        }
        this.sendMessage(msgObj2, participant)
      })
      this.getLivekitRoom.on(RoomEvent.ParticipantDisconnected, () => {
        console.log(this.getParticipants)
        this.emptyRoomTimer = setTimeout(() => {
          const url = `${resourceMain.API.server_manager.root}/${api.POST_STOP_RENDER}`
          if (this.getParticipants.length === 0) {
            axios({
              url,
              method: 'POST',
              data: {
                workspace: this.$store.state.workspace,
              },
            })
          }
        }, 15000)
      })
    },
    // hostConnect() {
    //   this.getLivekitRoom.on(RoomEvent.ParticipantConnected, (participant) => {
    //     const msgObj1 = {
    //       payload: this.scene.id,
    //       type: MESSAGE_EVENTS.CHECK_ACTIVE_SCENE_ID,
    //     }
    //     this.sendMessage(msgObj1, participant)
    //   })
    // },
    guestConnectUpdateScene() {
      const guetsLoaderScene = true
      this.getLivekitRoom.on(RoomEvent.TrackPublished, (_, participant) => {
        const msgObj1 = {
          payload: [
            this.scene,
            guetsLoaderScene,
            this.getInteractiveBg,
            this.getInteractiveTitle,
          ],
          type: MESSAGE_EVENTS.ACTIVE_SCENE_UPDATE,
        }
        this.sendMessage(msgObj1, participant)
      })
    },
    // guestPresentatiomConnectUpdateScene() {
    //   this.getLivekitRoom.on(RoomEvent.ParticipantConnected, (participant) => {
    //     const msgObj = {
    //       payload: this.scene.presentations,
    //       type: MESSAGE_EVENTS.PRESENTATION_APPEAR,
    //     }
    //     this.sendMessage(msgObj, participant)
    //   })
    // },
    playVideo({ id, currentTime }) {
      const videoEl = this.$refs.activeScene.$refs[`video${id}`][0]
      videoEl.currentTime = currentTime
      videoEl.play()
    },
    pauseVideo({ id }) {
      const videoEl = this.$refs.activeScene.$refs[`video${id}`][0]
      // this.disconnectAudio('video', this.video.audioSource)
      videoEl.pause()
    },
    async playMusic({ volume, isLooping }) {
      console.log('playMusic')
      const musicEl = this.$refs.activeScene.$refs.backgroundmusic?.[0]
      if (musicEl) {
        //   try {
        //     this.music.audioSource =
        //       // this.music.audioSource ||
        //       this.audioContext.createMediaElementSource(musicEl)
        //   } catch (e) {
        //     console.log(e)
        //   }
        musicEl.addEventListener('canplay', await this.canPlay())
        // this.connectAudio('music', this.music.audioSource)
        musicEl.loop = !!isLooping
        musicEl.volume = volume / 100
        musicEl.onended = () => {
          this.stopMusic()
        }
      }
    },
    async canPlay() {
      const musicEl = this.$refs.activeScene.$refs.backgroundmusic?.[0]
      await musicEl.play().then(() => {
        this.sendMessageToAll({
          type: MESSAGE_EVENTS.PLAY_MUSIC,
          payload: {
            time: this.$refs.activeScene.$refs.backgroundmusic?.[0].currentTime,
          },
        })
        if (this.$refs.activeScene.$refs.backgroundmusic?.[0].loop) {
          this.sendMessageToAll({
            type: MESSAGE_EVENTS.TOGGLE_LOOPING,
            payload: { loop: true },
          })
        }
        musicEl.removeEventListener('canplay', this.canPlay)
      })
    },
    pauseMusic() {
      console.log('pauseMusic')
      const musicEl = this.$refs.activeScene.$refs.backgroundmusic?.[0]
      // this.disconnectAudio('music', this.music.audioSource)
      musicEl?.pause()
      this.sendMessageToAll({
        type: MESSAGE_EVENTS.PAUSE_MUSIC,
      })
    },
    stopMusic() {
      console.log('stopMusic')
      const musicEl = this.$refs.activeScene.$refs.backgroundmusic?.[0]
      if (musicEl) {
        musicEl.pause()
        musicEl.currentTime = 0
      }
      this.sendMessageToAll({
        type: MESSAGE_EVENTS.STOP_MUSIC,
      })
    },
    onToggleLooping(isLooping) {
      console.log('onToggleLooping')
      const musicEl = this.$refs.activeScene.$refs.backgroundmusic?.[0]
      musicEl.loop = isLooping
      this.sendMessageToAll({
        type: MESSAGE_EVENTS.TOGGLE_LOOPING,
        payload: { loop: isLooping },
      })
    },
    updateAudioOratorsOnActiveScene() {
      console.log('[updateAudioOratorsOnActiveScene]')
      if (
        !this.$refs.activeScene.$refs.backgroundmusic?.[0].paused &&
        this.$refs.activeScene.$refs.backgroundmusic?.[0].currentTime
      ) {
        this.sendMessageToAll({
          type: MESSAGE_EVENTS.PLAY_MUSIC,
          payload: {
            time: this.$refs.activeScene.$refs.backgroundmusic?.[0].currentTime,
          },
        })
        if (this.$refs.activeScene.$refs.backgroundmusic?.[0].loop) {
          this.sendMessageToAll({
            type: MESSAGE_EVENTS.TOGGLE_LOOPING,
            payload: { loop: true },
          })
        }
      }
    },
    async startRelay() {
      await this.getDestinationsList()
      this.getDestinations
        .filter((destination) => destination.is_enabled)
        .forEach((destination) => {
          this.startRelayForDestination(destination)
        })
    },
    startRelayForDestination(destination) {
      console.log('startRelayForDestination')
      const url = `${resourceMain.API.api_studio.root}${api.POST_MAKE_FLASHPHONER_REQUEST}`
      const rtmp_url =
        destination.destination === 'proofix'
          ? this.rtmpUrl
          : `${destination.data.link}/${destination.data.key}`

      axios({
        url,
        method: 'POST',
        data: {
          workspace: this.$store.state.workspace,
          call: 'startup',
          stream_name: this.getRemoteVideoSettings.stream_name,
          // stream_name: this.publishedStream.main?.name(),
          rtmp_url,
          width: 1920,
          height: 1080,
        },
        headers: { Authorization: `Bearer ${getCookie('token-s')}` },
      })
        .then(({ data }) => {
          const res = data
          if (res.data.mediaSessionId) {
            // clearInterval(this.startRelayIntervalList[destination.id])

            const newData = {
              media_session_id: res.data.mediaSessionId,
              stream_name: res.data.streamName,
              rtmp_url: res.data.rtmpUrl,
              is_online: true,
            }

            this.updateDestinationLocalParams({
              id: destination.id,
              destination: newData,
            })
            this.sendMessageToAll({
              type: MESSAGE_EVENTS.UPDATE_DESTINATION,
              payload: { id: destination.id, newData },
            })
            destination = { ...destination, ...newData }
          } else {
            console.log(
              `(ошибка старта)${destination.name || destination.title}: ${
                res.data.message
              }`
            )
          }
        })
        .catch(() => {
          console.log('Start relay for destination error')
        })
    },
    stopRelay() {
      this.getDestinations
        .filter((destination) => destination.is_online)
        .forEach((destination) => {
          this.stopRelayForDestination(destination)
        })
    },
    stopRelayForDestination(destination) {
      const url = `${resourceMain.API.api_studio.root}${api.POST_MAKE_FLASHPHONER_REQUEST}`

      axios({
        url,
        method: 'POST',
        data: {
          workspace: this.$store.state.workspace,
          call: 'terminate',
          media_session_id: destination.media_session_id,
        },
        headers: { Authorization: `Bearer ${getCookie('token-s')}` },
      })
        .then(() => {
          const newData = {
            media_session_id: null,
            stream_name: null,
            rtmp_url: null,
            is_online: false,
          }
          this.updateDestinationLocalParams({
            id: destination.id,
            destination: newData,
          })

          this.sendMessageToAll({
            type: MESSAGE_EVENTS.UPDATE_DESTINATION,
            payload: { id: destination.id, newData },
          })
        })
        .catch(() => {
          console.log('Stop relay for destination error')
        })
    },
    checkRelayHealthForDestination(destination) {
      const url = `${resourceMain.API.api_studio.root}${api.POST_MAKE_FLASHPHONER_REQUEST}`
      console.log('destination: ' + destination)
      console.log('destination stream_name: ' + destination.stream_name)
      console.log('destination rtmp_url: ' + destination.rtmp_url)
      axios({
        url,
        method: 'POST',
        data: {
          workspace: this.$store.state.workspace,
          call: 'find',
          stream_name: destination.stream_name,
          rtmp_url: destination.rtmp_url,
        },
        headers: { Authorization: `Bearer ${getCookie('token-s')}` },
      })
        .then(({ data }) => {
          const res = data
          if (res.data.message) {
            clearInterval(this.checkHealthIntervalList[destination.id])

            console.log(
              `Check relay health for destination error ${destination.id} ${
                destination.name || destination.title
              }: ${res.data.message}`
            )

            this.startRelayIntervalList[destination.id] = setInterval(() => {
              this.startRelayForDestination(destination)
            }, 5000)
          }
        })
        .catch(() => {
          console.log('Check relay health for destination error')
        })
    },
    sendMessage(msgObj, participant) {
      this.getLocalParticipant[1].publishData(
        encode(JSON.stringify(msgObj.payload)),
        {
          topic: msgObj.type,
          destination: [participant],
          reliable: true,
        }
      )
    },
    sendMessageToAll(msgObj) {
      const payload = msgObj.payload || null
      this.getLocalParticipant[1].publishData(encode(JSON.stringify(payload)), {
        topic: msgObj.type,
        reliable: true,
      })
    },
  },
}
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
  .fade-leave-to

  /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.test {
  height: 190px;
  width: 340px;
  border: 1px solid rgb(185, 185, 195);
  border-radius: 5px;
  background-color: rgb(0, 0, 0);
}

.the-stream {
  height: 100%;
  width: 100%;

  .localVideo {
    display: none;
  }

  &__column_preview {
    width: 370px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__container {
    height: 100%;
    width: 100%;
    padding: 0px;
    display: flex;
    justify-content: center;
  }

  &__column {
    height: 100%;
    width: 100%;

    &_menu {
      width: 54px;
    }

    &_submenu {
      min-width: 220px;
      width: 220px;
    }

    &_player {
      width: 33%;
      max-width: 1280px;
      padding: 0 12px 12px;
      flex-shrink: 1;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      overflow: auto;
    }

    &_preview {
      width: 370px;
    }
  }

  &__player-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-top: 12px;
    margin-bottom: 20px;
  }

  .orator-display {
    max-width: 200px;
    height: 110px;
    margin: 0 auto;
    border-radius: $border-radius;
    border: 1px solid #b9b9c3;
    overflow: hidden;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;

    &.show-background {
      background: url('~@/images/account_circle.png') center center no-repeat;
      background-size: cover;

      video {
        visibility: hidden;
      }
    }

    video {
      height: 105%;
      object-fit: contain;
    }
  }

  &__canvas {
    display: none;
  }

  &__video-container {
    max-height: 90%;

    &__item {
      width: 340px;
      height: 190px;
      background: black;
      border-radius: $border-radius;
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: center;
      margin-bottom: 8px;

      video {
        height: 105%;
        object-fit: contain;
      }

      &__name {
        position: absolute;
        bottom: 5px;
        left: 5px;
        color: white;
      }

      &.show-background {
        background: url('~@/images/account_circle.png') center center no-repeat;
        background-size: cover;

        video {
          visibility: hidden;
        }
      }
    }

    &__remote-orators {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      padding: 8px;
    }

    &__presentation-image,
    &__stream-preview {
      height: 95%;
      margin-bottom: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 8px;

      img {
        max-height: 100%;

        &.hidden {
          display: none;
        }
      }

      video {
        height: 100%;
        width: 100%;
        border-radius: $border-radius;
      }
    }

    &__presentation-image {
      position: relative;

      .controls-wrapper {
        visibility: hidden;
        position: absolute;
        bottom: 24px;
        left: calc(50% - 57px);
      }

      &:hover {
        .controls-wrapper {
          visibility: visible;
        }
      }
    }
  }

  &__video {
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 100%;
    border: 1px solid #b9b9c3;
    border-radius: 5px;
  }

  &__local-display {
    height: 190px;
    width: 340px;
    margin: 0 auto;
    border: 1px solid #b9b9c3;
    border-radius: $border-radius;
    background-color: #000000;
    overflow: hidden;

    video {
      width: 102%;
      height: 102%;
      transform: translate(-1%, -1%);
    }
  }

  &__modal {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(0, 0, 0, 0.7);
  }

  &__settings {
    margin: 0 5px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }

  &__scenes {
    flex-grow: 1;
    flex-shrink: 1;
    max-height: 150px;
  }

  &__container-mobile {
    height: calc(100% - 54px);

    .the-stream {
      &__menu-footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 1;
      }

      &__video-container {
        height: calc(100vw / 16 * 9);

        &__stream-preview {
          height: 100%;
          margin: 0;
        }

        &__item {
          width: 48%;
          height: 100px;
          overflow: hidden;
        }

        &__presentation-image {
          height: 100%;
          margin: 0;

          img {
            border-radius: $border-radius;
          }

          .controls-wrapper {
            position: static;
            visibility: visible;
            margin-top: 12px;
          }
        }
      }

      &__orators {
        height: calc(100% - 56.25vw);
      }
    }
  }
}

.chat {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
</style>
