import Vue from 'vue'
import Vuex from 'vuex'
import devices from './modules/devices'
import studio from './modules/studio'
import stream from './modules/stream'
import branding from './modules/branding'
import audio from './modules/audio'
import room from './webrtc-core/room'
import participant from './webrtc-core/participant'
import track from './webrtc-core/track'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isStreaming: true,
    isSharingScreen: false,
    sharingStream: null,
    isStartMoveOrator: false,
    isStartMoveDecoration: false,
    isStartMoveBackground: false,
    isSettingsModalVisible: false,
    audioContext: null,
    workspace: null,
  },
  getters: {
    getSharingStream: (state) => state.sharingStream,
  },
  mutations: {
    UPDATE_STREAM_STATUS(state, status = false) {
      state.isStreaming = status
    },
    UPDATE_SHARING_SCREEN_STATUS(state, status = false) {
      state.isSharingScreen = status
    },
    UPDATE_MOVE_ORATOR_STATUS(state, status = false) {
      state.isStartMoveOrator = status
    },
    UPDATE_MOVE_DECORATION_STATUS(state, status = false) {
      state.isStartMoveDecoration = status
    },
    UPDATE_MOVE_BACKGROUND_STATUS(state, status = false) {
      state.isStartMoveBackground = status
    },
    UPDATE_MODAL_SETTINGS_VISIBLE(state, status = true) {
      state.isSettingsModalVisible = status
    },
    UPDATE_AUDIO_CONTEXT(state, payload) {
      state.audioContext = payload
    },
    UPDATE_WORKSPACE(state, workspace) {
      state.workspace = workspace
    },
    SET_SHARING_STREAM(state, stream) {
      state.sharingStream = stream
    },
  },
  actions: {},
  modules: {
    devices,
    studio,
    stream,
    branding,
    audio,
    room,
    track,
    participant,
  },
})
