export default {
  namespaced: true,
  state: () => ({
    activeSceneAudio: null,
  }),
  getters: {
    getActiveSceneAudio: (state) => {
      return state.activeSceneAudio
    },
  },
  actions: {
    setActiveSceneAudio({ commit }, audio) {
      commit('SET_ACTIVE_SCENE_AUDIO', audio)
    },
    setActiveSceneAudioLooping({ commit }, loop) {
      commit('SET_ACTIVE_SCENE_AUDIO_LOPING', loop)
    },
    setActiveSceneAudioVolume({ commit }, volume) {
      commit('SET_ACTIVE_SCENE_AUDIO_VOLUME', volume)
    },
    setActiveSceneAudioCurrentTime({ commit }, time) {
      commit('SET_ACTIVE_SCENE_AUDIO_CURRENT_TIME', time)
    },
  },
  mutations: {
    SET_ACTIVE_SCENE_AUDIO(state, url) {
      state.activeSceneAudio = new Audio(url)
      state.activeSceneAudio.loop = false
      state.activeSceneAudio.crossOrigin = 'anonymous'
      state.activeSceneAudio.volume = 1
    },
    SET_ACTIVE_SCENE_AUDIO_LOPING(state, loop) {
      state.activeSceneAudio.loop = loop
    },
    SET_ACTIVE_SCENE_AUDIO_VOLUME(state, volume) {
      state.activeSceneAudio.volume = volume
    },
    SET_ACTIVE_SCENE_AUDIO_CURRENT_TIME(state, time) {
      state.activeSceneAudio.currentTime = time
    },
  },
}
