export default class RtmpDestination {
  constructor(data = {}) {
    this.id = data.id || null

    // Interface props
    this.destination = data.destination
    this.title = data.title
    this.name = data.name
    this.is_enabled = data.is_enabled
    this.is_editable = data.is_editable ?? true
    this.data = data.data ?? {}

    // Props from Flashphoner response
    this.media_session_id = null
    this.stream_name = null
    this.rtmp_url = null

    this.is_online = false
  }
}
