import Vue from 'vue'
import VIDEO from '@/constants/video'
export default {
  namespaced: true,
  state: () => ({
    devices: [],
    currentDevicesId: {
      currentAudioId: null,
      currentVideoId: null,
    },
    isMicOn: true,
    isVideoOn: true,
  }),
  getters: {
    getDevices: (state) => state.devices,
    getCurrentDevicesId: (state) => state.currentDevicesId,
    getAudioDevices: (state) =>
      state.devices.filter((item) => item.kind === 'audioinput'),
    getVideoDevices: (state) =>
      state.devices.filter((item) => item.kind === 'videoinput'),
    isMicOn: (state) => state.isMicOn,
    isVideoOn: (state) => state.isVideoOn,
  },
  actions: {
    async requestDevices({ commit }) {
      return navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          commit('SET_DEVICES', devices)
        })
        .catch((e) => console.error(e))
    },
    async getAccessToDevices({ state }) {
      try {
        const constraints = {
          audio: {
            deviceId: state.currentDevicesId.currentAudioId,
            echoCancellation: true,
            googEchoCancellation: true,
          },
          video: {
            deviceId: state.currentDevicesId.currentVideoId,
            bitrate: VIDEO.MAX_BITRATE,
            ...(Vue.prototype.$isMobile &&
            window.innerHeight > window.innerWidth
              ? {
                  width: { ideal: VIDEO.IDEAL_WIDTH_MOBILE },
                  height: { ideal: VIDEO.IDEAL_HEIGHT_MOBILE },
                  aspectRatio: { ideal: VIDEO.ASPECT_RATIO_MOBILE },
                }
              : {
                  width: { ideal: VIDEO.IDEAL_WIDTH },
                  height: { ideal: VIDEO.IDEAL_HEIGHT },
                  aspectRatio: { ideal: VIDEO.ASPECT_RATIO },
                }),
          },
        }

        if (!state.isMicOn) {
          constraints.audio = false
        }

        if (!state.isVideoOn) {
          constraints.video = false
        }
        return navigator.mediaDevices.getUserMedia(constraints)
      } catch (e) {
        console.log('get user media error: ', e)
      }
    },
    setCurrentDevicesId({ commit }, currentDevicesId) {
      commit('SET_CURRENT_DEVICES_ID', currentDevicesId)
    },
    toggleMic({ commit }, micState) {
      commit('TOGGLE_MIC', micState)
    },
    toggleVideo({ commit }, videoState) {
      commit('TOGGLE_VIDEO', videoState)
    },
  },
  mutations: {
    SET_DEVICES(state, devices) {
      state.devices = devices
    },
    SET_CURRENT_DEVICES_ID(state, currentDevicesId) {
      state.currentDevicesId = currentDevicesId
    },
    TOGGLE_MIC(state, micState) {
      state.isMicOn = micState
    },
    TOGGLE_VIDEO(state, videoState) {
      state.isVideoOn = videoState
    },
  },
}
