module.exports = {
  NODE_ENV: process.env.NODE_ENV,
  APP: {
    title: 'Nuxt Boilerplate',
    pwa: {
      manifest: {
        name: 'Nuxt Proofix',
        short_name: 'Nuxt Proofix',
        description: 'Nuxt Boilerplate by Proofix',
      },
    },
  },
  KEYCLOAK: {
    url: 'https://auth.stage.proofix.ru/auth',
    realm: 'proofix',
    clientId: 'frontend',
    onLoad: 'login-required', // [check-sso | login-required]
    redirectUri: 'https://my.stage.proofix.ru/',
  },
  API: {
    main: {
      root: 'https://api.stage.proofix.ru/api/',
      static: process.env.STATIC_RESOURCE || false,
    },
    api_studio: {
      root: 'https://api-studio.stage.proofix.ru/api/',
      static: process.env.STATIC_RESOURCE || false,
    },
    server_manager: {
      root: 'https://server-render-manager.stage.proofix.ru',
      static: process.env.STATIC_RESOURCE || false,
    },
    ws_url: {
      root: 'wss://meet-wsss.proofix.ru',
      static: process.env.STATIC_RESOURCE || false,
    },
  },
  RENDER_STUDIO: {
    stopRender: 'https://server-render-manager.stage.proofix.ru/',
  },
  DOMAINS: {
    public: 'https://stage.proofix.ru',
    studio: 'https://studio.stage.proofix.ru',
    streamStudio: 'https://webrtc.stage.proofix.ru',
  },
  INTEGRATIONS: {
    ip: '84.23.52.151',
  },
  PROOFIX_CHAT: {
    url: 'static.proofix.ru/widgets/chat/proofix-chat.js?v1.00.14',
  },
}
