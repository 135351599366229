import axios from 'axios'
import api from '@/constants/api'
import resourceMain from '/config'
import { getCookie } from '@/helpers/cookie'

export default {
  namespaced: true,
  state: () => ({
    primaryColor: '#0079c2',
    secondaryColor: '#0079c2',
    id: null,
    interactiveBg: '#fff',
    interactiveTitle: '',
  }),
  getters: {
    getPrimaryColor: (state) => state.primaryColor,
    getSecondaryColor: (state) => state.secondaryColor,
    getBrandingId: (state) => state.id,
    getInteractiveBg: (state) => state.interactiveBg,
    getInteractiveTitle: (state) => state.interactiveTitle,
  },
  actions: {
    setPrimaryColor({ commit }, color) {
      commit('PRIMARY_COLOR', color)
    },
    setSecondaryColor({ commit }, color) {
      commit('SECONDARY_COLOR', color)
    },
    setBrandingId({ commit }, id) {
      commit('BRANDING_ID', id)
    },
    async getRemoteSavedState({ rootState, commit }) {
      try {
        const url = `${resourceMain.API.api_studio.root}${api.GET_BRANDING}`
        const {
          data: {
            data: { list },
          },
        } = await axios({
          url,
          method: 'GET',
          params: { workspace: rootState.workspace },
          headers: { Authorization: `Bearer ${getCookie('token-s')}` },
        })

        const branding = list?.[list.length - 1]

        if (branding?.id) {
          commit('PRIMARY_COLOR', branding.primary_color)
          commit('SECONDARY_COLOR', branding.secondary_color)
          commit('BRANDING_ID', branding.id)
        }
      } catch (e) {
        console.log('get branding error: ', e)
      }
    },
  },
  mutations: {
    PRIMARY_COLOR(state, color) {
      state.primaryColor = color
    },
    SECONDARY_COLOR(state, color) {
      state.secondaryColor = color
    },
    BRANDING_ID(state, id) {
      state.id = id
    },
    INTERACTIVE_BACKGROUND(state, interactiveBg) {
      state.interactiveBg = interactiveBg
    },
    INTERACTIVE_TITLE(state, interactiveTitle) {
      state.interactiveTitle = interactiveTitle
    },
  },
}
