<template lang="pug">
div
  v-button.v-translation-control(
    v-if='!isMobileDevice'
    @click.native="leaveConference"
    icon="RadioIcon"
    size="14"
  ) Выйти из мероприятия
  template(v-else)
      v-pill(v-if="getCurrentRoom === 'live'") Вы в прямом эфире 
      v-pill(v-else color="#54BDEA") Вы в Зале ожидания
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'VTranslationControl',
  components: {
    VButton: () => import('@/components/v-button'),
    VPill: () => import('@/components/v-pill'),
  },
  data: () => ({}),
  computed: {
    ...mapGetters('studio', ['getCurrentRoom']),
    isMobileDevice() {
      return this.$isMobile
    },
    currentRoom: {
      get() {
        return this.getCurrentRoom
      },
    },
  },
  methods: {
    leaveConference() {
      this.$store.commit('UPDATE_STREAM_STATUS', this.isStreaming)
    },
  },
}
</script>

<style lang="scss">
.v-translation-control {
  @media (max-width: 800px) {
    padding: {
      right: 10px;
      left: 10px;
    }
  }
}
</style>
