import axios from 'axios'
import resourceMain from '/config'
import api from '@/constants/api'
import { getCookie } from '@/helpers/cookie'

import RtmpDestination from '@/models/rtmp-destination'
import VideoSettings from '@/models/video-settings'

export default {
  namespaced: true,
  state: () => ({
    destinations: [],
    onlineDestinations: [],
    enabledDestinations: [],
    session: null,
    room: null,
    waitingRoom: null,
    remoteVideoSettings: null,
    socketServerURL: null,
  }),
  getters: {
    getDestinations: (state) => state.destinations,
    getRemoteVideoSettings: (state) => state.remoteVideoSettings,
    getSocketServerURL: (state) => state.socketServerURL,
    getOnlineDestinations: (state) => state.onlineDestinations,
    getEnabledDestinations: (state) => state.enabledDestinations,
    getSession: (state) => state.session,
    getRoom: (state) => state.room,
    getWaitingRoom: (state) => state.waitingRoom,
  },
  actions: {
    // addLocalDestination({ commit }, destination) {
    //   commit('ADD_DESTINATION', destination)
    // },
    // addDestination({ commit, rootState }, destination) {
    //   axios
    //     .post(
    //       `${resourceMain.API.api_studio.root}${api.POST_CREATE_DESTINATION}`,
    //       {
    //         workspace: rootState.workspace,
    //         destination: destination.destination,
    //         title: destination.title,
    //         name: destination.name,
    //         is_enabled: destination.is_enabled,
    //         data: destination.data,
    //       }
    //     )
    //     .then(({ data: { data } }) => {
    //       destination.id = data.id
    //       commit('ADD_DESTINATION', destination)
    //     })
    //     .catch(() => {
    //       Vue.prototype.$awn.alert('Ошибка добавления направления')
    //     })
    // },
    // async updateDestination({ commit }, { id, destination }) {
    //   axios
    //     .put(
    //       `${
    //         resourceMain.API.api_studio.root
    //       }${api.PUT_UPDATE_DESTINATION_BY_ID.replace(':id', id)}`,
    //       destination
    //     )
    //     .then(() => {
    //       commit('UPDATE_DESTINATION', { id, destination })
    //     })
    //     .catch(() => {
    //       Vue.prototype.$awn.alert('Ошибка изменения направления')
    //     })
    // },
    updateDestinationLocalParams({ commit }, { id, destination }) {
      commit('UPDATE_DESTINATION', { id, destination })
    },
    // removeDestination({ commit }, id) {
    //   axios
    //     .delete(
    //       `${
    //         resourceMain.API.api_studio.root
    //       }${api.DELETE_DESTINATION_BY_ID.replace(':id', id)}`
    //     )
    //     .then(() => {
    //       commit('REMOVE_DESTINATION', id)
    //     })
    //     .catch(() => {
    //       Vue.prototype.$awn.alert('Ошибка удаления направления')
    //     })
    // },
    async getDestinationsList({ commit, rootState }) {
      commit('RESET_DESTINATIONS')
      try {
        const url = `${
          resourceMain.API.api_studio.root
        }${api.GET_DESTINATIONS.replace(':workspace', rootState.workspace)}`

        const {
          data: { data },
        } = await axios({
          url,
          method: 'GET',
          headers: { Authorization: `Bearer ${getCookie('token-s')}` },
        })

        data.list.forEach((item) => {
          commit('ADD_DESTINATION', new RtmpDestination(item))
        })
      } catch (e) {
        console.log(e, 'get relay destinations list error')
      }
    },
    async loadRemoteVideoSettings({ commit, rootState }) {
      try {
        const url = `${
          resourceMain.API.main.root
        }${api.GET_VIDEO_SETTINGS.replace(
          ':workspace_id',
          rootState.workspace
        )}`
        const {
          data: { data },
        } = await axios({
          url,
          method: 'GET',
          headers: { Authorization: `Bearer ${getCookie('token-s')}` },
        })
        commit(
          'UPDATE_REMOTE_VIDEO_SETTINGS',
          new VideoSettings(data.list?.[0])
        )
        commit(
          'UPDATE_SOCKET_SERVER_URL',
          `wss://${data.list?.[0]?.origin?.origin}:8443`
        )
      } catch (e) {
        console.log('get remote video settings error: ', e)
      }
    },
    // setSession({ commit }, session) {
    //   commit('SET_SESSION', session)
    // },
    // setRoom({ commit }, room) {
    //   commit('SET_ROOM', room)
    // },
    // setWaitingRoom({ commit }, waitingRoom) {
    //   commit('SET_WAITING_ROOM', waitingRoom)
    // },
  },
  mutations: {
    ADD_DESTINATION(state, destination) {
      state.destinations.push(destination)

      if (destination.is_online) {
        state.onlineDestinations.push(destination.id)
      }

      if (destination.is_enabled) {
        state.enabledDestinations.push(destination.id)
      }
    },
    RESET_DESTINATIONS(state) {
      state.destinations = [
        new RtmpDestination({
          id: 'proofix',
          destination: 'proofix',
          title: 'Пруфикс',
          name: '',
          is_enabled: true,
          is_editable: false,
          // data: { rtmp_url: this.rtmpUrl },
        }),
      ]
    },
    UPDATE_REMOTE_VIDEO_SETTINGS(state, settings) {
      state.remoteVideoSettings = settings
    },
    UPDATE_SOCKET_SERVER_URL(state, url) {
      state.socketServerURL = url
    },
    UPDATE_DESTINATION(state, { id, destination }) {
      const destinationIndex = state.destinations.findIndex(
        (el) => el.id === id
      )

      const newData = {
        ...state.destinations[destinationIndex],
        ...destination,
      }

      state.destinations[destinationIndex] = newData

      const onlineIndex = state.onlineDestinations.indexOf(newData.id)

      if (newData.is_online) {
        if (onlineIndex === -1) {
          state.onlineDestinations.push(newData.id)
        }
      } else {
        if (onlineIndex !== -1) {
          state.onlineDestinations.splice(onlineIndex, 1)
        }
      }

      const enabledIndex = state.enabledDestinations.indexOf(newData.id)

      if (newData.is_enabled) {
        if (enabledIndex === -1) {
          state.enabledDestinations.push(newData.id)
        }
      } else {
        if (enabledIndex !== -1) {
          state.enabledDestinations.splice(enabledIndex, 1)
        }
      }
    },
    // REMOVE_DESTINATION(state, id) {
    //   state.destinations.splice(
    //     state.destinations.findIndex((el) => el.id === id),
    //     1
    //   )

    //   const onlineIndex = state.onlineDestinations.indexOf(id)
    //   if (onlineIndex !== -1) {
    //     state.onlineDestinations.splice(onlineIndex, 1)
    //   }

    //   const enabledIndex = state.enabledDestinations.indexOf(id)
    //   if (enabledIndex !== -1) {
    //     state.enabledDestinations.splice(enabledIndex, 1)
    //   }
    // },
    // SET_SESSION(state, session) {
    //   state.session = session
    // },
    // SET_ROOM(state, room) {
    //   state.room = room
    // },
    // SET_WAITING_ROOM(state, waitingRoom) {
    //   state.waitingRoom = waitingRoom
    // },
  },
}
