import {
  createLocalVideoTrack,
  createLocalAudioTrack,
  createLocalScreenTracks,
  VideoPresets,
} from 'livekit-client'
import Vue from 'vue'
export default {
  namespaced: true,
  state: () => ({
    tracks: [],
    createdTracks: [],
    localAudioTrack: null,
    localVideoTrack: null,
  }),
  getters: {
    // Получить медиастрим
    getParticipantStream: (_, getters) => (participantIdentity, sources) => {
      const mediaStream = new MediaStream()
      sources?.forEach((source) => {
        const track = getters.getParticipantTrack(participantIdentity, source)
        if (track && track.track?.mediaStream) {
          mediaStream.addTrack(track.track.mediaStream.getTracks()[0])
        }
      })
      return mediaStream
    },

    //Получить livekit трек
    getParticipantTrack: (state) => (participantIdentity, source) => {
      const track = state.tracks[participantIdentity]?.find(
        (elem) => elem.source === source
      )
      console.log(track?.track)
      return track
    },

    getLocalAudioTrack(state) {
      return state.localAudioTrack
    },
    getLocalVideoTrack(state) {
      return state.localVideoTrack
    },

    // Включена ли камера у участника
    participantCameraMuted: (state, getters) => (participant) => {
      if (!participant) {
        return true
      }
      if (!getters.getParticipantTrack(participant[1].identity, 'camera')) {
        return true
      }
      if (
        !getters.getParticipantTrack(participant[1].identity, 'camera')?.track
      ) {
        return true
      }
      return getters.getParticipantTrack(participant[1].identity, 'camera')
        ?.track?.isMuted
    },
    // Включен ли микрофон у участника
    participantMicrophoneMuted: (state, getters) => (participant) => {
      if (!participant) {
        return true
      }
      if (!getters.getParticipantTrack(participant[1].identity, 'microphone')) {
        return true
      }
      if (
        !getters.getParticipantTrack(participant[1].identity, 'microphone')
          ?.track
      ) {
        return true
      }
      return getters.getParticipantTrack(participant[1].identity, 'microphone')
        ?.track?.isMuted
    },

    participantScreenShareMuted: (state, getters) => (participant) => {
      if (!participant) {
        return true
      }
      if (
        !getters.getParticipantTrack(participant[1]?.identity, 'screen_share')
      ) {
        return true
      }
      if (
        !getters.getParticipantTrack(participant[1].identity, 'screen_share')
          ?.track
      ) {
        return true
      }
      return getters.getParticipantTrack(
        participant[1].identity,
        'screen_share'
      )?.track?.isMuted
    },
  },
  actions: {
    async createLocalVideoTrack({ commit, dispatch }) {
      const videoTrack = await createLocalVideoTrack()
      console.log(videoTrack)
      commit('SAVE_LOCAL_VIDEO_TRACK', videoTrack)
      dispatch('replaceScreenTrack')
      // await rootState.participant.localParticipant[1].publishTrack(videoTrack)
    },
    async createLocalAudioTrack({ commit }) {
      const audioTrack = await createLocalAudioTrack()
      commit('SAVE_LOCAL_AUDIO_TRACK', audioTrack)
    },
    async createLocalScreenTracks({ rootState }) {
      const videoTrack = await createLocalScreenTracks()
      await rootState.participant.localParticipant[1].publishTrack(videoTrack)
    },
    async createLocalTracks({ rootState, commit }) {
      const videoTrack = await createLocalVideoTrack()
      const audioTrack = await createLocalAudioTrack()
      // const screentracks = await createLocalScreenTracks()

      await rootState.participant.localParticipant[1].publishTrack(audioTrack)
      await rootState.participant.localParticipant[1].publishTrack(videoTrack)
      commit('SET_LOCAL_TRACK', rootState.participant.localParticipant)
      const localParticipant = rootState.participant.localParticipant[1]
      // await localParticipant.createTracks()
      localParticipant.tracks.forEach(async (track) => {
        console.log(track)
        // track.pauseUpstream()
        // await rootState.participant.localParticipant[1].publishTrack(track)
      })
      // await dispatch('muteLocalAudioTrack', true)
      // await dispatch('muteLocalVideoTrack', true)
      // rootState.participant.localParticipant[1].publishTrack(audioTrack)
    },
    async setEnableScreenShare({ commit, rootState }, status) {
      console.log(true)
      console.log(rootState.participant.localParticipant[1])
      const options = {
        preferCurrentTab: true,
        video: {
          displaySurface: 'browser',
          preferCurrentTab: true,
          selfBrowserSurface: 'include',
          resolution: {
            width: 1920,
            height: 1080,
            frameRate: 30,
            aspectRatio: 1920 / 1080,
          },
        },
      }
      await rootState.participant.localParticipant[1].setScreenShareEnabled(
        status,
        options
      )
      commit('SET_LOCAL_TRACK', rootState.participant.localParticipant)
      // dispatch('replaceScreenTrack')
    },
    async replaceScreenTrack({ rootState, commit }) {
      console.log(true)
      const constraints = {
        preferCurrentTab: true,
        audio: {
          sampleRate: 44100,
        },
        video: {
          type: 'screen',
          width: 1920,
          height: 1080,
          frameRate: { ideal: 30 },
        },
      }
      navigator.mediaDevices
        .getDisplayMedia(constraints)
        .then(async (stream) => {
          const newVideoTrack = stream.getTracks()[1]
          const newAudioTrack = stream.getTracks()[0]
          console.log(newVideoTrack)
          console.log(newAudioTrack)
          const track1 =
            await rootState.participant.localParticipant[1].publishTrack(
              newVideoTrack,
              {
                source: 'screen_share',
                videoSimulcastLayers: [
                  VideoPresets.h90,
                  VideoPresets.h90,
                  VideoPresets.h90,
                ],
              }
            )
          console.log(track1)
          const track2 =
            await rootState.participant.localParticipant[1].publishTrack(
              newAudioTrack,
              { source: 'screen_share_audio' }
            )
          commit('SAVE_LOCAL_VIDEO_TRACK', track1)
          commit('SAVE_LOCAL_AUDIO_TRACK', track2)
          console.log(track2)
          // tracks.forEach((track) => {
          //   if (track.source === 'camera') {
          //     track.track.replaceTrack(newTrack, true)
          //   }
          // })
        })
    },
    async unpublishLocalTracks({ state, rootState }) {
      await rootState.participant.localParticipant[1].unpublishTrack(
        state.localVideoTrack
      )
      await rootState.participant.localParticipant[1].unpublishTrack(
        state.localAudioTrack
      )
    },
    async publishLocalTracks({ state, rootState }) {
      await rootState.participant.localParticipant[1].publishTrack(
        state.localVideoTrack
      )
      await rootState.participant.localParticipant[1].publishTrack(
        state.localAudioTrack
      )
    },
    async stopUpstreamTracks({ rootState }, status) {
      const localParticipant = rootState.participant.localParticipant[1]
      console.log(localParticipant)
      const tracks = localParticipant.getTracks()
      console.log(tracks)
      if (status) {
        tracks.forEach(async (track) => {
          await track.pauseUpstream()
        })
      } else {
        tracks.forEach(async (track) => {
          await track.resumeUpstream()
        })
      }
    },
    async publishLocalTrack({ rootState, state, commit }, source) {
      const track = state.createdTracks.find((elem) => elem.source === source)
      await rootState.participant.localParticipant[1].publishTrack(track)
      console.log(true)
      commit('SET_LOCAL_TRACK', rootState.participant.localParticipant)
    },
    async muteLocalVideoTrack({ rootState, getters }, status) {
      const videoTrack = getters.getParticipantTrack(
        rootState.room.room.localParticipant.identity,
        'camera'
      )
      if (status) {
        return videoTrack.mute()
      }
      videoTrack.unmute()
    },
    async muteLocalAudioTrack({ rootState, getters }, status) {
      const audioTrack = getters.getParticipantTrack(
        rootState.room.room.localParticipant.identity,
        'microphone'
      )
      if (status) {
        return audioTrack.mute()
      }
      audioTrack.unmute()
    },
    replaceLocalTrack({ rootState, getters, commit }, payload) {
      const track = getters.getParticipantTrack(
        rootState.room.room.localParticipant.identity,
        payload.source
      )
      console.log(rootState.room.room.localParticipant.identity)
      console.log(payload.source)
      console.log(track)
      const mediastreamTrck = payload.newTrack
      // console.log(mediastreamTrck)
      // const constraints = {
      //   width: 1920,
      //   height: 1080,
      //   aspectRatio: 1.777777778,
      // }
      // mediastreamTrck.applyConstraints(constraints)
      console.log(mediastreamTrck)
      track.track.replaceTrack(mediastreamTrck, true)
      commit('SET_LOCAL_TRACK', rootState.participant.localParticipant)
      // track.track.setPublishingQuality(2)
    },
  },
  mutations: {
    SET_REMOTE_TRACKS(state, participants) {
      const paerticipantArr = Array.from(participants)
      paerticipantArr.forEach((participant) => {
        const trackPublications = []
        participant[1].getTrackPublications().forEach((trackPublication) => {
          trackPublications.push(trackPublication)
        })
        Vue.set(state.tracks, participant[1].identity, trackPublications)
      })
    },
    SET_LOCAL_TRACK(state, participant) {
      Vue.set(state.tracks, participant[1].identity, [])
      participant[1].getTrackPublications().forEach((track, index) => {
        Vue.set(state.tracks[participant[1].identity], [index], track)
      })
    },
    SET_CREATED_LOCAL_TRACK(state, newTrack) {
      const replacedTrack = state.createdTracks.find(
        (track) => track.source === newTrack.source
      )
      if (replacedTrack) {
        state.createdTracks.forEach((track, index) => {
          if (newTrack.source === track.source) {
            state.createdTracks[index] = newTrack
          }
        })
      } else {
        state.createdTracks.push(newTrack)
      }
    },
    SAVE_LOCAL_VIDEO_TRACK(state, track) {
      state.localVideoTrack = track
    },
    SAVE_LOCAL_AUDIO_TRACK(state, track) {
      state.localAudioTrack = track
    },
  },
}
