export default {
  PRESENTATION_PAGE_FORWARD: 'presentation_page_forward',
  PRESENTATION_PAGE_BACKWARD: 'presentation_page_backward',
  PRESENTATION_DISAPPEAR: 'presentation_disappear',
  PRESENTATION_APPEAR: 'presentation_appear',
  ACTIVE_SCENE: 'active_scene',
  ACTIVE_SCENE_UPDATE: 'active_scene_update',
  MUTE_ORATOR: 'mute_orator',
  UNMUTE_ORATOR: 'unmute_orator',
  CHANGE_ROOM: 'change_room',
  RENAME: 'rename',
  STOP_PUBLISHING: 'stop_publishing',
  START_PUBLISHING: 'start_publishing',
  UPDATE_SCENE: 'update_scene',
  VIDEO_IS_PLAYING: 'video_is_playing',
  MUSIC_IS_PLAYING: 'music_is_playing',
  MUSIC_IS_STOP: 'music_is_stop',
  CHANGE_ACTIVE_BANNER: 'change_active_banner',
  CHANGE_ACTIVE_TIMER: 'change_active_timer',
  CHANGE_BRANDING: 'change_branding',
  UPDATE_DESTINATION: 'update_destination',
  SET_MUSIC_LOOP: 'set_music_loop',
  CHECK_ACTIVE_SCENE_ID: 'check_active_scene_id',
  SERVER_RENDER_IS_READY: 'server_render_is_ready',
  START_MUSIC: 'start_music',
  PLAY_MUSIC: 'play_music',
  PAUSE_MUSIC: 'pause_music',
  STOP_MUSIC: 'stop_music',
  TOGGLE_LOOPING: 'toggle_looping',
  SET_TIMER: 'set_timer',
  CHANGE_PRES_PAGE: 'change_pres_page',
  GUEST_CONNECT: 'guest_connect',
  LOCAL_PARTICIPANT: 'local_participant',
  GET_SERVER_RENDER_ACTIVE_SCENE_ID: 'get_server_render_active_scene_id',
  CHECK_PRESENTATION_PAGE: 'check_presentation_page',
  ITERACTIVE_BACKGROUND: 'send_interactive_background',
}
