export default class BannerTimer {
  constructor(data = {}) {
    this.id = data?.id || null
    this.type = data?.type || 'banner'
    this.description = data?.description || ''
    this.options = data?.options || {}
    this.isActive = false

    if (!this.id) {
      if (this.type === 'banner') {
        this.options.ticker = false
      } else if (this.type === 'timer') {
        this.options.minutes = 0
        this.options.seconds = 0
        this.options.reverse = false
      }
    }
  }
}
