import {
  RoomEvent,
  Room,
  VideoPresets,
  VideoQuality,
  Track,
} from 'livekit-client'
import createNewId from '../../models/create-new-id'
import axios from 'axios'
import resourceMain from '/config'
import api from '@/constants/api'
import { getCookie } from '@/helpers/cookie'
export default {
  namespaced: true,
  state: () => ({
    room: null,
  }),
  getters: {
    getLivekitRoom: (state) => {
      return state.room
    },
  },
  actions: {
    async initWebrtc({ state, commit, dispatch }, { workspace, roomId }) {
      // await dispatch('setAudioOscillator')
      const wsURL = resourceMain.API.ws_url.root
      let token = ''
      const roomName = createNewId(roomId, workspace)
      const name = 'server-render'
      const identity = '0000-0000'
      await fetch(
        `${resourceMain.API.api_studio.root}getToken?identity=${identity}&name=${name}&room_name=${roomName}&canPublishData=1&can_update_own_meta_data=1&room_record=1`
      )
        .then((response) => {
          return response.json()
        })
        .then((data) => {
          token = data.data.token
        })
      try {
        await state.room.connect(wsURL, token)
      } catch (e) {
        console.error('Ошибка соединения с liveKit', e)
      }
      await dispatch('participant/setParticipant', state.room, { root: true })
      // await dispatch('track/createLocalVideoTrack', null, { root: true })
      // await dispatch('track/createLocalAudioTrack', null, { root: true })
      // await dispatch('track/stopUpstreamTracks', true, { root: true })
      await dispatch('track/replaceScreenTrack', null, { root: true })
      commit(
        'track/SET_LOCAL_TRACK',
        [state.room.localParticipant.sid, state.room.localParticipant],
        {
          root: true,
        }
      )
      state.room.on(RoomEvent.LocalTrackPublished, async () => {
        console.log(RoomEvent.LocalTrackPublished)
        if (
          Array.from(state.room.localParticipant.trackPublications).length !== 2
        )
          return
        setTimeout(async () => {
          await axios
            .post(
              `${resourceMain.API.api_studio.root}${api.START_EGRESS}`,
              {
                workspace: workspace,
                room_name: roomName,
              },
              {
                headers: {
                  Authorization: `Bearer ${getCookie('token-s')}`,
                },
              }
            )
            .then((res) => {
              // Обработка успешного ответа
              console.log(res.data)
            })
            .catch((error) => {
              if (error.response) {
                // Запрос был сделан и сервер ответил кодом статуса, который выпадает из диапазона 2xx
                console.log(error.response.data)
                console.log(error.response.status)
                console.log(error.response.headers)
                console.log(workspace)
                console.log(roomName)
              }
            })
        }, 3000)
      })
      dispatch('roomEvents')
    },
    setRoom({ commit }) {
      console.log(true)
      commit('SET_ROOM')
    },
    setAudioOscillator() {
      console.log('setAudioOscillator')
      var audioContext = new (window.AudioContext ||
        window.webkitAudioContext)()
      var oscillator = audioContext.createOscillator()
      oscillator.type = 'sine' // Тип волны (sine, square, sawtooth, triangle)
      oscillator.frequency.setValueAtTime(160, audioContext.currentTime) // Устанавливаем частоту в 440 Гц
      oscillator.connect(audioContext.destination)
      oscillator.start()
      // oscillator.stop(audioContext.currentTime + 1) // Остановка воспроизведения через 1 секунду
    },
    roomEvents({ state, commit }) {
      state.room.on(RoomEvent.ParticipantConnected, () => {
        console.log(RoomEvent.ParticipantConnected)
        commit(
          'participant/SET_REMOTE_PARTICIPANTS',
          state.room.remoteParticipants,
          {
            root: true,
          }
        )
      })
      state.room.on(RoomEvent.ParticipantDisconnected, () => {
        console.log(RoomEvent.ParticipantDisconnected)
        commit(
          'participant/SET_REMOTE_PARTICIPANTS',
          state.room.remoteParticipants,
          {
            root: true,
          }
        )
      })
      state.room.on(RoomEvent.TrackPublished, () => {
        console.log(RoomEvent.TrackPublished)
        commit(
          'participant/SET_REMOTE_PARTICIPANTS',
          state.room.remoteParticipants,
          {
            root: true,
          }
        )
      })
      state.room.on(RoomEvent.TrackMuted, () => {
        console.log(RoomEvent.TrackMuted)
        // commit('participant/SET_REMOTE_PARTICIPANTS', state.room.participants, {
        //   root: true,
        // })
      })
      state.room.on(RoomEvent.TrackUnmuted, () => {
        console.log(RoomEvent.TrackUnmuted)
        // commit('participant/SET_REMOTE_PARTICIPANTS', state.room.participants, {
        //   root: true,
        // })
      })
      state.room.on(RoomEvent.Cancelled, () => {
        console.error(RoomEvent.Cancelled)
      })
      state.room.on(RoomEvent.NotAllowed, () => {
        console.error(RoomEvent.NotAllowed)
      })
      state.room.on(RoomEvent.InternalError, () => {
        console.error(RoomEvent.InternalError)
      })
      state.room.on(RoomEvent.ServerUnreachable, () => {
        console.error(RoomEvent.ServerUnreachable)
      })
      state.room.on(RoomEvent.TrackSubscribed, (track, publication) => {
        if (track.kind === Track.Kind.Video) {
          publication.setVideoQuality(VideoQuality.HIGH)
        }
      })
    },
  },
  mutations: {
    SET_ROOM(state) {
      const options = {
        adaptiveStream: { pixelDensity: 3 },
        resolution: VideoPresets.h1080,
        //   // dynacast: true,
        // videoSimulcastLayers: [VideoPresets.h90],
        // videoCaptureDefaults: {
        //   resolution: {
        //     height: 90,
        //     width: 160,
        //   },
        // },
      }
      console.log(VideoPresets)
      state.room = new Room(options)
    },
  },
}
