import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'
import LayoutMain from '../layout/layout-main.vue'
import TheStream from '../views/the-stream.vue'
import VTranslationControl from '../components/v-translation-control.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: LayoutMain,
    children: [
      {
        name: 'stream',
        path: '',
        components: {
          default: TheStream,
          header: VTranslationControl,
        },
      },
    ],
  },
]

const router = new VueRouter({
  //mode: "history",
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.query.workspace) {
    store.commit('UPDATE_WORKSPACE', to.query.workspace)
  }
  next()
})

export default router
